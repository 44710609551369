import React from 'react';
import LayoutBasic from '../components/layout/LayoutBasic';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import iso9001Hun from '../assets/Certop_9001_COhun.png';
import iso9001Eng from '../assets/Certop_9001_COeng.png';
import iso9001CertHun from '../assets/tanusitvany_MIR_magyar_HU20993-23_2023-12-13.pdf';
import iso9001CertEng from '../assets/tanusitvany_MIR_angol_HU20993-23_2023-12-13.pdf';

import iso27001Hun from '../assets/Certop_27001_COhun.png';
import iso27001Eng from '../assets/Certop_27001_COeng.png';
import iso27001CertHun from '../assets/tanusitvany_IBIR_magyar_HU20994-23_2024-06-21.pdf';
import iso27001CertEng from '../assets/tanusitvany_IBIR_angol_HU20994-23_2024-06-21.pdf';

export default function ISO() {  
  const intl = useIntl();
  const iso9001PdfSrc = intl.locale == 'hu' ? iso9001CertHun : iso9001CertEng;
  const iso27001PdfSrc = intl.locale == 'hu' ? iso27001CertHun : iso27001CertEng;

  let isos = [
    {
      id: "9001",
      name: "ISO 9001",
      txtId: "iso.9001.text1",
      imgSrc: intl.locale == 'hu' ? iso9001Hun : iso9001Eng,
    },
    {
      id: "27001",
      name: "ISO 27001",
      txtId: "iso.27001.text1",
      imgSrc: intl.locale == 'hu' ? iso27001Hun : iso27001Eng,
    }
  ];

  const downloadPdf = (pdfType) => {
    if(pdfType == "9001"){
      window.open(iso9001PdfSrc, "_blank", "noreferrer");
    }
    if(pdfType == "27001"){
      window.open(iso27001PdfSrc, "_blank", "noreferrer");
    }
  }

  return (
    <>
      <LayoutBasic>
        <section className="lg:w-4/6 md:5/6 w-11/12 flex flex-col justify-center gap-y-8 mx-auto py-10 md:px-10 px-4">
          <div className="flex flex-col justify-center text-center py-8 md:px-10 px-8 bg-white rounded-3xl">
            <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold"><FormattedMessage id={"iso.title"}  
              values={{
                b: chunks => <b>{chunks}</b>,
              }} />
            </h1>
            <p className="pt-4 pb-1 text-justify"><FormattedMessage id={"iso.subtitle1"} 
              values={{
                b: chunks => <b>{chunks}</b>,
              }}/>
            </p>
            <p className="pt-1 pb-4 text-justify"><FormattedMessage id={"iso.subtitle2"}
              values={{
                b: chunks => <b>{chunks}</b>,
              }} />
            </p>
          </div>

          {isos.map(iso => (
            <div id="isoContainer" className="flex flex-col lg:flex-row justify-between items-center py-8 lg:px-10 md:px-6 px-4 bg-white rounded-3xl">
              <img src={iso.imgSrc} className="isoLogo iso my-auto p-8 w-1/2" />
              <div className="flex flex-col mx-auto md:px-10 px-5 py-5">
                <h2 className="lg:text-4xl text-3xl font-bold">{iso.name}</h2>
                <p className="py-4 lg:text-xl text-base"><FormattedMessage id={iso.txtId} 
                  values={{
                    b: chunks => <b>{chunks}</b>,
                  }} />
                </p>
                <div className="mx-auto mt-4">
                  <button className="py-2 px-16 lg:text-lg text-base bg-primary text-gray-200 rounded-3xl bold" onClick={() => downloadPdf(iso.id)}>
                  <FormattedMessage id={"iso.download"} 
                    values={{
                      b: chunks => <b>{chunks}</b>,
                    }} 
                  />
                  </button>
                </div>
              </div>
            </div>
          ))}

      </section>
    </LayoutBasic>
    <div className="w-full h-screen overflow-hidden fixed top-0" style={{zIndex: -1, backgroundColor: "#d9f3fa"}}>
    <div className="fixed inset-x-0 top-0" style={{zIndex: -1}}>
    <svg id="visual" viewBox="0 0 1920 600" width="1920" height="600" xmlns="http://www.w3.org/2000/svg" version="1.1"><path d="M0 263L64 252.2C128 241.3 256 219.7 384 194.2C512 168.7 640 139.3 768 134.7C896 130 1024 150 1152 162.8C1280 175.7 1408 181.3 1536 175.8C1664 170.3 1792 153.7 1856 145.3L1920 137L1920 0L1856 0C1792 0 1664 0 1536 0C1408 0 1280 0 1152 0C1024 0 896 0 768 0C640 0 512 0 384 0C256 0 128 0 64 0L0 0Z" fill="#95cfde"></path><path d="M0 76L64 84.8C128 93.7 256 111.3 384 119.7C512 128 640 127 768 125.5C896 124 1024 122 1152 131.8C1280 141.7 1408 163.3 1536 174.3C1664 185.3 1792 185.7 1856 185.8L1920 186L1920 0L1856 0C1792 0 1664 0 1536 0C1408 0 1280 0 1152 0C1024 0 896 0 768 0C640 0 512 0 384 0C256 0 128 0 64 0L0 0Z" fill="#61a4b6"></path><path d="M0 124L64 113.2C128 102.3 256 80.7 384 80.2C512 79.7 640 100.3 768 108.5C896 116.7 1024 112.3 1152 113.7C1280 115 1408 122 1536 111.3C1664 100.7 1792 72.3 1856 58.2L1920 44L1920 0L1856 0C1792 0 1664 0 1536 0C1408 0 1280 0 1152 0C1024 0 896 0 768 0C640 0 512 0 384 0C256 0 128 0 64 0L0 0Z" fill="#257a8f"></path></svg>
      </div>
      <div className="fixed inset-x-0 bottom-0" style={{zIndex: -1}}>
        <svg id="visual" viewBox="0 0 1920 400" width="1920" height="600" xmlns="http://www.w3.org/2000/svg" version="1.1"><path d="M0 398L64 400.8C128 403.7 256 409.3 384 389.2C512 369 640 323 768 312.5C896 302 1024 327 1152 333.7C1280 340.3 1408 328.7 1536 312.8C1664 297 1792 277 1856 267L1920 257L1920 601L1856 601C1792 601 1664 601 1536 601C1408 601 1280 601 1152 601C1024 601 896 601 768 601C640 601 512 601 384 601C256 601 128 601 64 601L0 601Z" fill="#95cfde"></path><path d="M0 411L64 410.8C128 410.7 256 410.3 384 410.3C512 410.3 640 410.7 768 387.3C896 364 1024 317 1152 322.7C1280 328.3 1408 386.7 1536 396C1664 405.3 1792 365.7 1856 345.8L1920 326L1920 601L1856 601C1792 601 1664 601 1536 601C1408 601 1280 601 1152 601C1024 601 896 601 768 601C640 601 512 601 384 601C256 601 128 601 64 601L0 601Z" fill="#61a4b6"></path><path d="M0 448L64 445.3C128 442.7 256 437.3 384 437C512 436.7 640 441.3 768 438.7C896 436 1024 426 1152 436.7C1280 447.3 1408 478.7 1536 481.2C1664 483.7 1792 457.3 1856 444.2L1920 431L1920 601L1856 601C1792 601 1664 601 1536 601C1408 601 1280 601 1152 601C1024 601 896 601 768 601C640 601 512 601 384 601C256 601 128 601 64 601L0 601Z" fill="#257a8f"></path></svg>
      </div>
    </div>
  </>
  );
}

